import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Img from "gatsby-image";
import styles from "@styles/pages/home.module.css";
import NewsletterForm from '@components/newsletterForm';


const IndexPage = ({ data }) => (
  <Layout>
    <SEO title="Official Site" />
    <section className={styles.logo}>
      <Img fluid={data.logo.childImageSharp.fluid} loading="eager" alt="EIS Logo" />
    </section>
    <section className={styles.signup}>
      <NewsletterForm />
    </section>
  </Layout>
)

export default IndexPage

export const pageQuery = graphql`
  {
    logo: file(relativePath: { eq: "EIS_DollarSign_White.png" }) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  
  }
`;
