import React, { useState } from "react"
import styles from '@styles/components/newsletterForm.module.css'
import { ToastContainer, toast, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `useStaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `useStaticQuery`: https://www.gatsbyjs.org/docs/use-static-query/
 */

const NewsletterForm = () => {
    const [formData, setFormData] = useState({
        fullName: "",
        email: "",
        city: "",
        state: "",
        country: ""
    })
    const [isLoading, setIsLoading] = useState(false);
    const handleChange = (event) => {
        const value = event.target.value;
        const key = event.target.id;
        const data = {}
        data[key] = value;
        setFormData({ ...formData, ...data })
    }
    const signupUser = (event) => {
        event.preventDefault();
        setIsLoading(true);
        const vars = {
            "myItemName": formData.fullName,
            "columnVals": JSON.stringify({
                "email": {
                    "email": formData.email,
                    "text": formData.email
                },
                "text7": formData.city,
                "text1": formData.state,
                "text8": formData.country
            })
        };
        fetch('https://api.monday.com/v2', {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'eyJhbGciOiJIUzI1NiJ9.eyJ0aWQiOjU5NjM3MTg5LCJ1aWQiOjEwMzU0ODc3LCJpYWQiOiIyMDIwLTA3LTE0VDE4OjU2OjAyLjAwMFoiLCJwZXIiOiJtZTp3cml0ZSJ9.ufo3IwzNlN64-O8jUspQJ3bBAmzsUqNZ6rHmd8SXI6A'
            },
            method: 'post',
            body: JSON.stringify({
                'query': `mutation ($myItemName: String!, $columnVals: JSON!) {
                create_item (board_id: 710720216, item_name: $myItemName, column_values: $columnVals ) {
                  id
                  name
                  state
                }
              }`,
                'variables': JSON.stringify(vars)
            })

        }).then((result) => {
            setFormData({
                fullName: "",
                email: "",
                city: "",
                state: "",
                country: ""
            })
            toast('signup successful.', {
                type: toast.TYPE.DARK,
            })
            setIsLoading(false);
        }).catch(function (error) {
            toast('error with signup, please try again.', {
                type: toast.TYPE.DARK,
            })
            setIsLoading(false);
        });
    }

    return (
        <>
        <ToastContainer transition={Slide} />
        {isLoading ? <div className={styles.loader}></div> : <></> }
        <h3 className={styles.header}>Fan Club</h3>
        <form className={styles.form} onSubmit={signupUser} id="signup">


            <div className={`${styles.field} ${styles.fieldL}`}>
                
                <input type="text" placeholder="your name" name="fullName" id="fullName" required onChange={handleChange} value={formData.fullName} />
                <label htmlFor="fullName">Full Name</label>
            </div>

            <div className={`${styles.field} ${styles.fieldR}`}>
                
                <input type="email" placeholder="youremail@example.com" name="email" id="email" required onChange={handleChange} value={formData.email} />
                <label htmlFor="email">Email</label>
            </div>

            <div className={`${styles.field} ${styles.fieldL}`}>
                
                <input type="text" placeholder="your city" name="city" id="city" required onChange={handleChange} value={formData.city} />
                <label htmlFor="city">City</label>
            </div>
            <div className={`${styles.field} ${styles.fieldR}`}>
                
                <input type="text" placeholder="your state" name="state" id="state" onChange={handleChange} value={formData.state} />
                <label htmlFor="state">State</label>
            </div>
            <div className={`${styles.field} ${styles.field1}`}>
                
                <input type="text" placeholder="your country" name="country" id="country" onChange={handleChange} value={formData.country} />
                <label htmlFor="country">Country</label>
            </div>

        
        </form>
        <button disabled={isLoading} className={styles.button} type="submit" form="signup" value="Submit">Submit</button>
        </>
    )
}

export default NewsletterForm
